<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The procedure for this experiment tells you to use
        <number-value :value="initialMass" unit="\text{mg}" /> of 4-<i>t</i>-butylcyclohexanone.
        Once the reaction, workup, and purification steps are complete, you obtain
        <number-value :value="finalMass" unit="\text{mg}" /> of final product. What is the percent
        yield for your reaction?
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\%\text{ yield:}$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LCPL4BQ2',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    initialMass() {
      return this.taskState.getValueBySymbol('initialMass').content;
    },
    finalMass() {
      return this.taskState.getValueBySymbol('finalMass').content;
    },
  },
};
</script>
